import * as React from 'react';
import * as styles from './section-header.module.css';

function SectionHeader({ children }) {
  return (
    <h2 className={styles.title}>{children}</h2>
  );
}

export default SectionHeader;
